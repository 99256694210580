import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    meta: {
      title: '嘉韵楷达'
    },
    component: HomeView
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      title: '嘉韵楷达-产品与服务'
    },
    component: () => import('../views/ProductionAndServer.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: '嘉韵楷达-公司新闻'
    },
    component: () => import('../views/CompanyNews.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '嘉韵楷达-关于我们'
    },
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: '嘉韵楷达-联系我们'
    },
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/newsdetail/:id',
    name: 'newsdetail',
    meta: {
      title: '嘉韵楷达-新闻详情'
    },
    component: () => import('../views/NewsDetail.vue'),
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
