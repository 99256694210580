<template>
  <div id="app">
    <nav>
      <img src="./assets/imgs/logo.png" alt="" srcset="" />
      <ul>
        <router-link to="/"><Icon name="home" />首页</router-link>
        <router-link to="/product"
          ><Icon name="production" />产品与服务</router-link
        >
        <router-link to="/news"><Icon name="news" />公司新闻</router-link>
        <router-link to="/about"><Icon name="aboutus" />关于我们</router-link>
        <router-link to="/contact"
          ><Icon name="contact" />联系我们</router-link
        >
      </ul>
    </nav>
    <router-view />
    <index-bottom />
  </div>
</template>
<script>
import Icon from "./components/Icon.vue";
import IndexBottom from "./components/IndexBottom.vue";
export default {
  components: { Icon, IndexBottom }
};
</script>
<style lang="less" scoped>
#app {
  position: relative;
  min-height: 100vh;
}

nav {
  display: flex;
  background: #2462b6;
  justify-content: space-between;
  padding: 28px 67px;
  img {
  }
  ul {
    display: flex;
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      margin-right: 18px;
      /deep/ .icon {
        margin-right: 8px;
      }
      &.router-link-exact-active {
        color: #0b2c57;
      }
    }
  }
}
</style>
