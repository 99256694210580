<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../assets/imgs/home/banner.jpg" alt="" />
      <ul>
        <li>气象预见未来 双碳绿色发展</li>
        <li>深耕气象服务领域 积极应对气候变化</li>
        <li>践行“双碳”之路 发展低碳农业 营造绿色生态</li>
      </ul>
    </div>
    <div class="company-news">
      <img class="back" src="../assets/imgs/home/bkg1.png" alt="" />
      <div class="company-main">
        <h3>公司新闻</h3>
        <div class="card-group">
          <div
            class="card"
            v-for="item in list"
            :key="item.contentId"
            @click="toNews(item.contentId)"
          >
            <img :src="'http://api.giantweather.cn' + item.typeImg" alt="" />
            <div class="card-desc">
              <span>{{ item.shortTitle || item.title }}</span>
              <p>
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
        <button
          @click="
            () => {
              $router.push('/news');
            }
          "
        >
          查看更多
        </button>
      </div>
    </div>
    <div class="production-server">
      <div class="production-server-main">
        <h3>产品与服务 — 气象与科技</h3>
        <div class="content">
          <div class="left">
            <span>气象数据加工</span>
            <div class="server">
              <img src="../assets/imgs/home/server1.png" alt="" />
              <span>气象大数据融合，产品融合，服务融合</span>
            </div>
            <span>行业气象服务</span>
            <div class="server">
              <img src="../assets/imgs/home/server2.png" alt="" />
              <span>智慧农业气象</span>
              <img src="../assets/imgs/home/server3.png" alt="" />
              <span>智慧工程气象</span>
            </div>
          </div>
          <div class="right">
            <img src="../assets/imgs/home/computer.png" alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
    <div class="production-server2">
      <img class="back" src="../assets/imgs/home/bkg2.jpg" alt="" />
      <div class="production-server2-main">
        <h3>产品与服务 — 低碳与农业</h3>
        <div class="content">
          <div class="item">
            <img src="../assets/imgs/home/server4.png" alt="" srcset="" />
            <span>碳足迹，记录农业生产低碳活动</span>
          </div>
          <div class="item">
            <img src="../assets/imgs/home/server5.png" alt="" srcset="" />
            <span>碳计算，提供清晰碳排放量记录工具</span>
          </div>
          <div class="item">
            <img src="../assets/imgs/home/server6.png" alt="" srcset="" />
            <span>碳管理，实现企业绿色价值</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { contentList } from "../service/cms.js";
export default {
  name: "HomeView",
  data() {
    return {
      list: []
    };
  },
  methods: {
    _contentList() {
      contentList(119, 1, 3).then(
        (res) => {
          if (res.data.code === 200 && res.data.success === true) {
            const data = res.data.data;
            this.list = data.records[0].contentList;
          }
        },
        (err) => {
          throw err;
        }
      );
    },
    toNews(id) {
      this.$router.push("/newsdetail/" + id);
    }
  },
  mounted() {
    this._contentList();
  }
};
</script>
<style lang="less" scoped>
.wrapper {
  h3 {
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #1f2d39;
    text-align: center;
    padding: 27px 0;
  }
  .banner {
    position: relative;
    img {
      width: 100%;
    }
    ul {
      position: absolute;
      top: 37%;
      left: 23%;
      li {
        font-size: 18px;
        font-family: Source Han Sans CN;
        color: #f7f8f9;
        margin: 12px 0;
        &:nth-child(1) {
          font-size: 26px;
          font-weight: bold;
        }
      }
    }
  }
  .company-news {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img.back {
      width: 100%;
    }
    .company-main {
      min-width: 940px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      .card-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .card {
          width: 297px;
          cursor: pointer;
          img {
            width: 297px;
            height: 220px;
            object-fit: cover;
          }
          .card-desc {
            background: #fff;
            text-align: center;
            padding: 22px;
            span {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #1f2d39;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 260px;
              display: block;
            }
            p {
              text-align: left;
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #999999;
              line-height: 22px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              word-wrap: break-word;
              word-break: break-all;
            }
          }
        }
      }
      button {
        width: 109px;
        height: 25px;
        border: 1px solid #bfbfbf;
        border-radius: 13px;
        margin-top: 23px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
      }
    }
  }
  .production-server {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 125px 0;
    .production-server-main {
      min-width: 940px;
      .content {
        display: flex;
        justify-content: space-between;
        > .left {
          display: flex;
          flex-direction: column;
          > span {
            font-size: 22px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #1f2d39;
          }
          .server {
            display: flex;
            align-items: center;
            padding: 30px 0;
            > img {
              width: 82px;
            }
            > span {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #1f2d39;
              padding: 0 21px;
            }
          }
        }
        > .right {
          img {
            width: 458px;
          }
        }
      }
    }
  }
  .production-server2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../assets/imgs/home/bkg2.jpg");
    position: relative;
    img.back {
      width: 100%;
    }
    .production-server2-main {
      width: 940px;
      position: absolute;
      top: 0;
      h3 {
        color: #fff;
      }
      .content {
        display: flex;
        justify-content: space-between;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 114px;
          }
          span {
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            margin-top: 47px;
          }
        }
      }
    }
  }
}
</style>
