import server from "./axios.conf.js";

/**
 * cms
   data: {
      channels: "75,94",
      pageNum: 1,
      pageSize: 3
    }
 */
export const contentList = function (channels, pageNum, pageSize) {
  return server({
    method: "post",
    url: `cms/content/contentList`,
    // data: params
    data: {
      channels,
      pageNum,
      pageSize
    }
  });
};
export const contentQueryById = function (id) {
  return server({
    method: "GET",
    url: `cms/content/contentQueryById?id=${id}`
  });
};
