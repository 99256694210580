<template>
  <svg class="icon">
    <use :xlink:href="'#' + name" />
  </svg>
</template>

<script>
// 引入整个文件夹
const importAll = (requireContext) => {
  requireContext.keys().forEach(requireContext);
};
try {
  importAll(require.context("../assets/icons", true, /\.svg$/));
} catch (e) {
  console.log(e);
}
export default {
  props: ["name"],
  name: "Icon"
};
</script>

<style lang="less" scoped>
.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  overflow: hidden;
}
</style>
